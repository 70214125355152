import React from 'react';

function CoverPage(props) {
  return (
      <>
        <img src='/assets/images/bg2.jpg' className="print-report-cover"  alt="" />
        <img src='/assets/images/ZoomRx_logo.png' className="print-report-cover-logo-header" alt="" />
        <p className="text-weight-bold text-size-medium print-report-cover-org org-name">ZoomRx</p>
        <p className="text-weight-bold text-size-medium print-report-cover-org org-report">CASE STUDY REPORT</p>
        <div className="ui grid margin-no padding-sm-top">
          <div className="ten wide column padding-bg">
            <div className="ui grid">
              <div className="twelve wide middle aligned column margin-top-thirty">
                <div className="text-size-small padding-bg-left">
                  <div>Name: <span className="text-weight-medium padding-vs">{props.fullName}</span></div>
                  <div>Role: <span className="text-weight-medium padding-vs">{props.data.assessment && props.data.assessment.name}</span></div>
                  <div>Date of Test: <span className="text-weight-medium padding-vs">{props && props.formatedDate}</span></div>
                </div>
              </div>
            </div>
          </div>
          <div className="six wide right aligned column padding-md">
            <img src='/assets/images/ZoomRx_logo.png' className="print-report-cover-logo-footer" alt="" />
          </div>
        </div>
      </>
    )
    
}

export default CoverPage;